


import Component from 'vue-class-component';
import {Vue, Watch} from 'vue-property-decorator';
import {mapGetters} from 'vuex';
import {TPromoPage} from '@/_types/promo-page/promo-page.type';
import {TranslateResult} from 'vue-i18n';
import LobbyBroadcastMediaBlock from './lobby-broadcast-media-block/lobby-broadcast-media-block.vue';
import _cloneDeep from 'lodash.clonedeep';

@Component({
  name: 'lobby-live-broadcasts',
  components: {
    LobbyBroadcastMediaBlock,
  },
  computed: {
    ...mapGetters({
      isPromoPageListLoading: 'promoStore/promoPageListLoading',
      promoPageList: 'promoStore/promoPages',
    }),
  }
})
export default class LobbyLiveBroadcasts extends Vue {

  public readonly isPromoPageListLoading: boolean;
  public readonly promoPageList: TPromoPage[];

  public promoPageListSlices: TPromoPage[][] = [];
  public standTexts: { [key: string]: TranslateResult };

  public isMediaItemsLength: boolean = false

  private promoPageListSliceIntervalId: number;
  private promoPageListSliceAmount: number = 60;

  public created(): void {
    this.standTexts = {
      inactive: this.$t('organizerCabinet.sections.lobby.standBroadcastInactive'),
      checking: this.$t('organizerCabinet.sections.lobby.standBroadcastChecking'),
    };
    this.callPromoPageList();
  }

  public getCompanyLogo(companyLogo: string): string {
    return companyLogo || require('@/assets/images/no-avatar-company-60x60.svg');
  }

  public get broadcastsOnly(): TPromoPage[] {
    const broadCasts: TPromoPage[] = [];
    const slices = _cloneDeep(this.promoPageListSlices);

    slices.forEach(item => {
      if (item.length) {
        for (let i = 0; i < item.length; i++) {
          const media = item[i].companyMediaItems;
          for (let j = 0; j < media.length; j++) {
            if (media[j].type === 'broadcast') {
              item[i].companyMediaItems = [media[j]];
              broadCasts.push(item[i]);
            } else {
              // console.log(item[i].companyMediaItems[j]);
              // item[i].companyMediaItems.splice(j, 1);
            }
          }
        }
      }
    });

    return (broadCasts.length && broadCasts);
  }

  @Watch('promoPageList', {immediate: true})
  private onPromoPageListChange(): void {
    this.startPromoPageListSlicing();
  }

  private startPromoPageListSlicing(): void {
    this.stopPromoPageListSlicing();

    this.promoPageListSlices = [];

    if (!this.promoPageList || !this.promoPageList.length) {
      return;
    }
    this.setPromoPageListNextSlice();
    this.promoPageListSliceIntervalId = window.setInterval(() => {
      this.setPromoPageListNextSlice();
    }, 500);
  }

  private stopPromoPageListSlicing(): void {
    if (!this.promoPageListSliceIntervalId) {
      return;
    }
    clearInterval(this.promoPageListSliceIntervalId);
    this.promoPageListSliceIntervalId = null;
  }

  private setPromoPageListNextSlice(): void {
    if (this.promoPageListSlices.length >= Math.ceil(this.promoPageList.length / this.promoPageListSliceAmount)) {
      return;
    }
    const from = this.promoPageListSlices.length * this.promoPageListSliceAmount;
    const to = from + this.promoPageListSliceAmount;

    this.promoPageListSlices.push(this.promoPageList.slice(from, to));
  }

  private callPromoPageList(): void {
    this.$store.dispatch('promoStore/promoPageListAll', {event_id: this.$route.params.eventId});
  }

  public isShowItem(item: TPromoPage): boolean {
    if (item && item.companyMediaItems && item.companyMediaItems.length) {
      this.isMediaItemsLength = !!(item.companyMediaItems);
      return true;
    }
    return false;
  }

}
