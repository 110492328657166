var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"company-media-block"},[_c('div',{staticClass:"company-media-block-content"},[(_vm.mediaItems.length)?_c('div',{staticClass:"carousel-wrap"},[_c('mu-carousel',{attrs:{"active":_vm.activeSlideIndex,"cycle":false,"hide-indicators":_vm.mediaItems.length < 2,"hide-controls":_vm.mediaItems.length < 2},on:{"change":_vm.onCarouselItemChange}},_vm._l((_vm.mediaItems),function(item){return _c('mu-carousel-item',{key:item.id,ref:"carouselItems",refInFor:true,class:{
            'company-media-block-item-embed': item.type === 'embed',
            'company-media-block-item-video': item.type === 'video' || item.type === 'broadcast',
            'company-media-block-item-video-paused': item.type === 'video' && item.isPaused,
            'company-media-block-item-image': item.type === 'image',
            'company-media-block-item-video-broadcast': item.type === 'broadcast'
          },style:({
            'background-image' : item.type === 'image' ? 'url(' + item.src + ')' : 'none',
          }),attrs:{"id":item.id}},[(item.type === 'broadcast')?_c('div',{staticClass:"company-media-item",attrs:{"id":'company-media-item-' + item.id}},[_c('div',{staticClass:"preview-blurred"}),_c('div',{staticClass:"preview-dimmer"}),_c('div',{staticClass:"preview-contain cur-p"},[_c('stream-player',{attrs:{"src":item.src}})],1)]):(item.type === 'embed')?_c('div',{staticClass:"company-media-item",attrs:{"id":'company-media-item-' + item.id}},[_c('div',{staticClass:"preview-blurred"}),_c('div',{staticClass:"preview-dimmer"}),_c('div',{staticClass:"preview-contain cur-p video-stream-embed",domProps:{"innerHTML":_vm._s(item.src)}})]):(item.type === 'video')?_c('div',{staticClass:"company-media-item",attrs:{"id":'company-media-item-' + item.id}},[_c('div',{staticClass:"preview-blurred"}),_c('div',{staticClass:"preview-dimmer"}),_c('div',{staticClass:"preview-contain cur-p",on:{"click":function($event){return _vm.toggleVideoPlay($event)}}},[_c('video',{ref:"videoSlideContents",refInFor:true,staticClass:"cur-p",on:{"play":function($event){return _vm.onVideoPlayPauseChange(item, false)},"pause":function($event){return _vm.onVideoPlayPauseChange(item, true)}}},[_c('source',{attrs:{"src":item.src}})])])]):(item.type === 'image')?_c('div',{staticClass:"company-media-item",attrs:{"id":'company-media-item-' + item.id}},[_c('div',{staticClass:"preview-blurred",style:({
                'background-image' : 'url(' + item.src + ')',
              })}),_c('div',{staticClass:"preview-dimmer"}),_c('div',{staticClass:"preview-contain",style:({
                'background-image' : 'url(' + item.src + ')'
              })})]):_vm._e()])}),1)],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }