


import {Component, Ref, Vue, Watch} from 'vue-property-decorator';
import newsThumbnail from '@/_modules/news/components/news-thumbnail/news-thumbnail.vue';
import iconNews from '@/_modules/icons/components/sidebar/icon-news.vue';
import { TNewsIssue } from '@/_types/news-issue.type';
import newsApi from '@/_api/news.api';
import _cloneDeep from 'lodash.clonedeep';

const FALLBACK_FOR_WINDOW_WIDTH_CALCULATION = 360;
const DESIRED_MAX_WIDTH_OF_NEWS_THUMBNAIL = 378;

@Component({
  components: {
    newsThumbnail,
    iconNews
  }
})
export default class NewsListLobby extends Vue {

  @Ref('sliderWrap') sliderWrapRef: HTMLDivElement;

  public newsList: TNewsIssue[] = [];
  public newsSlides: TNewsIssue[][] = [];
  public isListLoading: boolean = false;
  public activeNewsSlideNumber: number = 0;
  public newsQuantityInSlide: number = 1;
  public isOnResizeDebounced: boolean = false;

  public get eventId(): number {
    return this.$route.params.eventId ? parseInt(this.$route.params.eventId, 10) : null;
  }

  public get isNewsListEmpty(): boolean {
    return !this.newsList || this.newsSlides.length === 0;
  }

  public get isMultipleSlides(): boolean {
    return !!(this.newsSlides && this.newsSlides.length > 1);
  }

  @Watch('newsList')
  public onNewsListChange(): void {
    this.initNewsSlides();
  }

  public mounted(): void {
    this.getNewsList();
    window.addEventListener('resize', this.onResize);
    this.onResize();
  }

  public beforeDestroy(): void {
    window.removeEventListener('resize', this.onResize);
  }

  public onResize(): void {
    if (this.isOnResizeDebounced) {
      return;
    }
    this.isOnResizeDebounced = true;
    const sliderWidth = (this.sliderWrapRef.getBoundingClientRect().width) || FALLBACK_FOR_WINDOW_WIDTH_CALCULATION;
    this.newsQuantityInSlide = Math.max(1, Math.floor(sliderWidth / DESIRED_MAX_WIDTH_OF_NEWS_THUMBNAIL));
    const currentSlide = (this.newsSlides && this.newsSlides.length && this.newsSlides[this.activeNewsSlideNumber]) || null;
    const firstIssueInViewId = (currentSlide && currentSlide[0] && currentSlide[0].id) || 0;
    this.newsSlides = [];
    this.initNewsSlides();
    this.activeNewsSlideNumber = 0;
    if (firstIssueInViewId) {
      const firstIssueIndex = this.newsList.findIndex(newsIssue => newsIssue.id === firstIssueInViewId);
      this.activeNewsSlideNumber = Math.floor(firstIssueIndex / this.newsQuantityInSlide);
    }
    window.setTimeout(() => { this.isOnResizeDebounced = false; }, 500); // N.B. we do not need it to happen often
  }

  public get slideStyles(): { [key: string]: any } {
    const frWidth = (1 / this.newsQuantityInSlide * 100).toFixed(0) + 'fr';
    return {
      'grid-template-columns': 'repeat(' + this.newsQuantityInSlide + ', ' + frWidth + ')'
    };
  }

  public onPrevSlideClick(): void {
    if (this.activeNewsSlideNumber - 1 < 0) {
      return;
    }

    this.activeNewsSlideNumber--;
  }

  public onNextSlideClick(): void {
    if (this.activeNewsSlideNumber + 1 >= this.newsSlides.length) {
      return;
    }

    this.activeNewsSlideNumber++;
  }

  public initNewsSlides(): void {
    const publishedNews = this.newsList.filter((newsIssue) => newsIssue.is_published);
    const result: TNewsIssue[][] = [];
    if (publishedNews && publishedNews.length) {
      let amountOfSlides = 1; // Let there be at least one slide

      // If it makes sense, lets calculate the amount of slides
      if (publishedNews.length > this.newsQuantityInSlide) {
        amountOfSlides = Math.ceil(publishedNews.length / this.newsQuantityInSlide);
      }

      // Using the amount of slides to create the array of dummy slides
      for (let i = 0; i < amountOfSlides; i++) {
        result.push([...publishedNews.slice(i * this.newsQuantityInSlide, i * this.newsQuantityInSlide + this.newsQuantityInSlide)]);
      }

      // Outputting the result into the data property
      this.newsSlides = _cloneDeep(result);
    }
  }

  private async getNewsList(): Promise<void> {
    this.isListLoading = true;
    this.newsList = await newsApi.getEventNews({ eventId: this.eventId, onlyPublished: true, offset: 0, limit: 100 });
    this.isListLoading = false;
  }

}
