import { render, staticRenderFns } from "./promo-live-new.html?vue&type=template&id=633a7b5c&scoped=true&"
import script from "./promo-live-new.vue?vue&type=script&lang=ts&"
export * from "./promo-live-new.vue?vue&type=script&lang=ts&"
import style0 from "./promo-live-new.scss?vue&type=style&index=0&id=633a7b5c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "633a7b5c",
  null
  
)

export default component.exports