import Vue from 'vue';

export class DateTimeHelper {
  static getMomentWithOffset(dateTimeStr) {
    return Vue.moment(dateTimeStr).add(Vue.moment().utcOffset(), 'minutes');
  }

  static isJSDate(input) {
    return input instanceof Date;
  }
}
